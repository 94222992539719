import React, { createContext, useState, useRef, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase/firebase';
export const AudioPlayerContext = createContext();

export const AudioPlayerProvider = ({ children }) => {
  const [currentTrack, setCurrentTrack] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [volume, setVolume] = useState(0.5);
  const audioRef = useRef(new Audio());
  const isChangingTrack = useRef(false);
  const [isAuth] = useAuthState(auth)

  useEffect(() => {
    const audio = audioRef.current;
    audio.volume = volume;

    audio.ontimeupdate = () => {
      setProgress((audio.currentTime / audio.duration) * 100);
    };

    audio.onended = () => setIsPlaying(false);

    const handleLoadedData = () => {
      setLoading(false);
      if (isPlaying) audio.play().catch(err => console.error("Error playing track:", err));
    };

    audio.addEventListener('loadeddata', handleLoadedData);
    if(!isAuth){
      return () => {
        audio.removeEventListener('loadeddata', handleLoadedData);
      };
    }
    return () => {
      isPlaying && !loading && audioRef.current.pause();
      audio.removeEventListener('loadeddata', handleLoadedData);
    };

  }, [isPlaying, volume, currentTrack,isAuth]);

  const playTrack = (track) => {
    // Prevent play/pause interruptions
    if (isChangingTrack.current) return;

    if (currentTrack && currentTrack.id === track.id) {
      if (!isPlaying) {
        audioRef.current.play().catch(err => console.error("Error playing track:", err));
        setIsPlaying(true);
      }
      return;
    }

    // Set the current track and start loading
    isChangingTrack.current = true; // Set the flag
    setCurrentTrack(track);
    audioRef.current.src = track.fileUrl;
    setLoading(true);
    
    audioRef && audioRef.current.play()
      .then(() => {
        setIsPlaying(true);
      })
      .catch((error) => {
        console.error("Error playing track:", error);
      })
      .finally(() => {
        isChangingTrack.current = false; // Reset the flag
        setLoading(false); // Ensure loading is set to false
      });
  };

  const pauseTrack = () => {
    audioRef.current.pause();
    setIsPlaying(false);
  };

  const seekTrack = (value) => {
    audioRef.current.currentTime = (audioRef.current.duration * value) / 100;
    setProgress(value);
  };

  const handleVolumeChange = (newVolume) => {
    setVolume(newVolume);
  };

  return (
    <AudioPlayerContext.Provider
      value={{
        currentTrack,
        isPlaying,
        playTrack,
        pauseTrack,
        progress,
        seekTrack,
        loading,
        volume,
        setVolume: handleVolumeChange,
      }}
    >
      {children}
    </AudioPlayerContext.Provider>
  );
};
