import { useEffect, useState } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { firestore } from "../firebase/firebase";

const useGetFeaturedSongsData = () => {
  const [songs, setSongs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSongs = () => {
      try {
        const songsCollection = collection(firestore, 'featuredTracks');
        const unsubscribeSongs = onSnapshot(songsCollection, (songsSnapshot) => {
          const songsList = songsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));
          

          const usersCollection = collection(firestore, 'users');
          const unsubscribeUsers = onSnapshot(usersCollection, (usersSnapshot) => {
            const usersMap = {};

            usersSnapshot.docs.forEach(userDoc => {
              const userData = userDoc.data();
              usersMap[userDoc.id] = userData;
            });  

            const enrichedSongs = songsList.map(song => ({
              ...song,
              user: usersMap[song.uid] || null,
            }));

            setSongs(enrichedSongs);
            setLoading(false);
          });

          return () => {
            unsubscribeUsers();
          };
        });

        return () => {
          unsubscribeSongs();
        };
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchSongs();
  }, []);

  return { songs, loading, error };
};

export default useGetFeaturedSongsData;
