import React from 'react';
import Slider from 'react-slick';
import { Box, Image, keyframes } from '@chakra-ui/react';
import poster1 from "../../assets/poster1.jpg";
import poster2 from "../../assets/poster2.jpg";
import poster3 from "../../assets/poster3.webp";
import poster4 from "../../assets/poster4.jpeg";
import poster5 from "../../assets/poster5.jpg";
import poster6 from "../../assets/poster6.jpg";

const dotAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
`;

const BannerCarousel = () => {
  const images = [poster1, poster2, poster3, poster4, poster5, poster6];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    appendDots: (dots) => (
      <Box
        position="absolute"
        bottom="20px"
        left="50%"
        transform="translateX(-50%)"
        zIndex="2"
      >
        <ul style={{ margin: 0, padding: 0, display: 'flex', justifyContent: 'center' }}>
          {dots}
        </ul>
      </Box>
    ),
    customPaging: (i) => (
      <Box
        as="div"
        width="12px"
        height="12px"
        background="gray.300"
        borderRadius="50%"
        margin="0 5px"
        animation={`${dotAnimation} 0.6s ease infinite`}
        _hover={{ background: "gray.500" }}
        _active={{ background: "gray.500", animation: `${dotAnimation} 1s ease-in-out` }}
      />
    ),
  };

  return (
    <Box
      width="95%"
      margin="auto"
      borderRadius="20px"
      position="relative"
      overflow="hidden"
    >
      <Slider {...settings}>
        {images.map((img, index) => (
          <Box key={index} position="relative">
            <Image
              src={img}
              alt={`Poster ${index + 1}`}
              objectFit="cover"
              width="100%"
              height="400px"
              borderRadius="20px"
            />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default BannerCarousel;
