import { Container, Flex, SimpleGrid, Text, useBreakpointValue } from '@chakra-ui/react'
import React from 'react'
import { user } from '../../constant'
import DashboardArtistCard from '../../Components/Card/DashboardArtistCard'
import AudioWaveform from '../../Components/Audio/AudioWaveForm'
import HomeBanner from '../../Components/Banners/HomeBanner'
import BannerCarousel from '../../Components/Banners/BannerCarousel'

const DashboardHome = () => {

  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Container maxW={'none'} 
    //  bg="var(--main-card-color)"
    color={"var(--primary-text-color)"}
    rounded="md"
    // boxShadow='rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
    // boxShadow='rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
    // boxShadow="var(--box-shadow)"

    >
      {/* <Flex gap={10} flexDirection={isMobile ? 'column' : 'row'}>
        <HomeBanner/>
        <HomeBanner/>
      </Flex> */}
      <BannerCarousel/>
      {/* <HomeBanner/> */}
      <SimpleGrid columns={[1,2,2,3,4]} gap={3}>
      {user?.map((user,i)=>{
        return(
          <DashboardArtistCard user={user} key={i}/>
        )
      })}
      </SimpleGrid>

    </Container>
  )
}

export default DashboardHome
