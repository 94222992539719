import { Box, Grid, SimpleGrid, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import Card from '../Card/Card'
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import useFeaturedArtistTracks from '../../store/featuredArtistTrack';
import useGetFeaturedSongsData from '../../hooks/useGetFeaturedSongs';
AOS.init({
    duration: 700,
})



const HeroSection = () => {
    const {songs ,loading} = useGetFeaturedSongsData()
    const {data ,setData,setLoader,loader} = useFeaturedArtistTracks()

    
    useEffect(() => {
        if (!data || data.length === 0) {
            setData(songs);
            setLoader(loading);
        }
    }, [songs, loading, setData, setLoader]);


  return (
    <>
        <Box textAlign={"center"} margin={{base:"30px 0 0 50px",md:"auto"}}>
            <Text paddingTop={{base:'20px',md:"100px"}} fontSize={{base:"3xl",md:'5xl'}} fontWeight={500} color='var(--primary-text-color)'>Featured Artists</Text>
        </Box>
        <Box display={"flex"} justifyContent={"center"}  paddingTop={"20px"}>
            <SimpleGrid columns={[1,2,3,4]} gap={8}>
                {data?.map((el,idx)=>{  
                    return (
                        <div key={idx} data-aos="flip-right" style={{width:'100%'}}>
                            <Card songData={el}/>
                        </div>
                        
                    )
                })}
            </SimpleGrid>
        </Box>
    </>
  )
}

export default HeroSection
