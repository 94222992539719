import { Box, Center, useColorModeValue, Text, Stack, Image, Button } from "@chakra-ui/react";
import { useContext, useState } from "react";
import { FaPause, FaPlay } from "react-icons/fa";


import { AudioPlayerContext } from "../../context/Audio/AudioPlayerContext";


export default function Card({ songData }) {
  const { currentTrack, isPlaying, playTrack, pauseTrack } = useContext(AudioPlayerContext);
  const [play, setPlay] = useState(true);

  const handleClickOfMusic = () => {
    if(isPlaying){
      setPlay(false)
      pauseTrack()
    }{
      setPlay(true);
      playTrack(songData)
    }
  };
  const isCurrentTrack = currentTrack && currentTrack.id === songData.id;
  return (
    <Center py={12}>
      <Box
        userSelect="none"
        role={"group"}
        p={7}
        w={"80%"}
        bg="var(--card-color)"
        boxShadow={"2xl"}
        rounded={"lg"}
        pos={"relative"}
        zIndex={1}
      >
        <Box
          rounded={"lg"}
          mt={-12}
          pos={"relative"}
          height={230}
          _after={{
            transition: "all .3s ease",
            content: '""',
            // w: "220px",
            // h: "210px",
            pos: "absolute",
            top: 9,
            left: 0,
            background: "var(--highlighted-color)",
            filter: "blur(15px)",
            zIndex: -1,
          }}
          _groupHover={{
            _after: {
              filter: "blur(20px)",
            },
          }}
        >
          <Image
            rounded={"lg"}
            height={240}
            width={"100%"}
            objectFit={"cover"}
            src={songData?.imageUrl}
            alt="#"
          />
        </Box>
        <Box marginTop={30} display={"flex"} flexDirection={"column"}>
          <Box display={"flex"} gap="16px">
            <Box
              cursor={"pointer"}
              bg="var(--highlighted-color-light)"
              onClick={handleClickOfMusic}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              width={"60px"}
              height={"50px"}
              borderRadius={"50%"}
            >
              {!isCurrentTrack && play ? <FaPlay fontSize={"14px"} /> : <FaPause fontSize={"14px"} />}
            </Box>
            <Box
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              width={"100%"}
              overflow="hidden" // Prevent overflow outside the box
            >
              <Box
                width="100%" // Set fixed width for the text area
                overflow="hidden" // Hide overflow to keep fixed width
                whiteSpace="nowrap" // Prevent wrapping
              >
                <Text fontSize={"2xl"}>
                <marquee direction="left" scrollamount="4">
                  {songData?.trackName}{" "}
                  <span style={{ fontSize: "14px", fontWeight: "normal" }}>
                    ({songData?.user?.fullName})
                  </span>
                </marquee>
                </Text>

              </Box>
            </Box>
          </Box>
          <Stack mt={{ base: 4, md: 4 }} direction={"row"} spacing={4}>
            <Button
              w={"full"}
              bg={useColorModeValue("#151f21", "gray.900")}
              color={"white"}
              rounded={"md"}
              _hover={{
                transform: "translateY(-2px)",
                boxShadow: "lg",
              }}
            >
              Follow
            </Button>
          </Stack>
        </Box>
      </Box>
    </Center>
  );
}
