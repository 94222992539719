
import { BottomNavigation, BottomNavigationIcon, BottomNavigationItem, BottomNavigationLabel } from 'chakra-ui-bottom-navigation';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { FaHome, FaInfo, FaUser } from 'react-icons/fa';
import { FiCompass } from 'react-icons/fi';
import { MdDashboard } from 'react-icons/md';
import {Link, useLocation} from "react-router-dom"
import { auth } from '../../firebase/firebase';
import { IoMdLogIn } from 'react-icons/io';
import { useModalVisibility } from '../../context/ModalToast/useModalToast';


export default function HomeBottomNavigationBar () {
    const [authUser] = useAuthState(auth)
    const [value, setValue] = useState(0);
    const { pathname } = useLocation();

    const isRenderNavbar = pathname.includes("/dashboard");

    

    const modal = useModalVisibility()
    const handleSignUpModal = ()=>{
        modal.toLoginOpen()
    }

    useEffect(()=>{
      if(pathname=='/dashboard'){
        setValue(1)
      }else if(pathname == '/about'){
        setValue(2)
      }else{
        setValue(0)
      }
    },[])


  
    return (
      <BottomNavigation value={value} p="12px" color='var(--highlighted-color)' onChange={setValue} borderRadius={"12px"} backdropFilter='blur(30px)' backgroundColor='rgba(255, 255, 255, 0.5)'>
        <BottomNavigationItem>
            <Link to='/'>
                <BottomNavigationIcon fontSize={"30px"} as={FaHome}/>
            </Link>
        </BottomNavigationItem>

          
        <BottomNavigationItem>
            {!authUser ? <BottomNavigationIcon onClick={handleSignUpModal} fontSize={"30px"} as={MdDashboard}/> :
          <Link to='/dashboard/trending'>
            <BottomNavigationIcon fontSize={"30px"} as={MdDashboard}/>
          </Link>}
        </BottomNavigationItem>
  
        <BottomNavigationItem>
        <Link to='/about'>
          <BottomNavigationIcon fontSize={"30px"} as = {FaInfo} />
          </Link>
        </BottomNavigationItem>


      </BottomNavigation>
    );
  };
  